import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/Settings.vue')
  },
  {
    path: '/balances',
    name: 'Balances',
    component: () =>
      import(/* webpackChunkName: "Balances" */ '../views/Balances.vue')
  },
  {
    path: '/refund-requests',
    name: 'RefundRequests',
    component: () =>
      import(/* webpackChunkName: "Balances" */ '../views/RefundRequests.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'Login' && !localStorage.getItem('currentUser')) {
    next({ name: 'Login' })
  } else next()
})

export default router
