<template>
  <v-dialog max-width="600" :value="show" @input="$emit('close')">
    <v-card>
      <v-container>
        <v-card-title>
          {{ $t('common.password_reset') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="resetPassword">
            <v-text-field
              v-model="oldPass"
              outlined
              :label="$t('common.old_password')"
              type="password"
              :rules="required"
            >
            </v-text-field>
            <v-text-field
              v-model="newPass"
              outlined
              :label="$t('common.new_password')"
              :rules="newPasswordRule"
              type="password"
            ></v-text-field>
            <v-text-field
              v-model="newPassAgain"
              outlined
              :rules="newPassAgainRule"
              :label="$t('common.new_password_again')"
              type="password"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="abandon">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn color="primary" @click="accept">
            {{ $t('common.accept') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ResetPassword',
  props: ['show', 'user', 'form'],
  data: () => ({
    oldPass: null,
    newPassAgain: null,
    newPass: null
  }),
  methods: {
    accept() {
      if (this.$refs.resetPassword.validate()) {
        this.$emit('save', { oldPass: this.oldPass, newPass: this.newPass })
        this.clearForm()
      }
    },
    abandon() {
      this.$emit('close')
      this.clearForm()
    },
    clearForm() {
      this.oldPass = null
      this.newPassAgain = null
      this.newPass = null
      this.$refs.resetPassword.resetValidation()
    }
  },
  watch: {},
  computed: {
    required() {
      return [v => !!v || this.$t('errors.required')]
    },
    newPasswordRule() {
      return [
        v =>
          ![' ', `'`, `;`, '\\', '%'].includes(v) ||
          'Kabul Edilmeyen karakterler'
      ]
    },
    newPassAgainRule() {
      return [
        !(this.newPass && this.newPass !== this.newPassAgain) ||
          this.$t('errors.passwords_not_match'),
        v => !!v || this.$t('errors.required')
      ]
    },
    username: function() {
      return JSON.parse(localStorage.getItem('currentUser')).username
    }
  }
}
</script>
