/* eslint-disable */
export default {
  install(Vue, options) {
    Vue.mixin({
      data: () => ({
        currentUser: JSON.parse(localStorage.getItem('currentUser')),
        loginError: false,
        tableLoading: false,
        responseErr: {
          isVisible: false,
          text: null,
          code: null
        },
        baseURL: null,
        isProdMode: false

        // for pagination
      }),
      mounted() {
        /* if (
          window.location.href.includes('sandbox') ||
          window.location.href.includes('localhost')
        ) {
          this.baseURL = options.sandboxURL
        } else {
          this.baseURL = options.productionURL
          this.isProdMode = true
        } */
        this.baseURL = options.productionURL
      },
      methods: {
        async makeReq({
          method,
          url,
          data = null,
          returnAll = false,
          showErr = true
        }) {
          this.tableLoading = true
          let headers = {}
          const currentUser = JSON.parse(localStorage.getItem('currentUser'))
          if (currentUser) {
            headers.Token = currentUser.token
            headers.User = currentUser.username
          }
          console.log(currentUser)
          let ctx = {
            method,
            headers: headers
          }
          if (method !== 'GET') ctx.body = JSON.stringify(data)

          try {
            const response = await fetch(
              `${this.baseURL}/paymapi/${
                /* this.isProdMode ? */ 'v1' /* : 'test' */
              }/${url}`,
              ctx
            )
            const data = await response.json()
            if (data.statusCode === 100) {
              /* if (data.data.token) {
                this.currentUser = { ...data.data };
                localStorage.setItem("currentUser", JSON.stringify(data.data));
                this.setToken(data.data.token);
              } */

              this.tableLoading = false
              return returnAll ? data : data.data
            }
            if (data.statusCode === 401) {
              if (this.$route.name === 'Login') {
                this.loginError = true
              } else {
                await this.$router.push({ name: 'Login' })
              }
            }
            // error case
            if (data.statusCode !== 100 && data.statusCode !== 401) {
              showErr
                ? (this.responseErr = Object.assign(
                    {},
                    {
                      isVisible: true,
                      text: data.statusMsg,
                      code: data.statusCode
                    }
                  ))
                : null
              this.tableLoading = false

              return { ...data, data: [] }
            }
          } catch (error) {
            console.log({ error })
          }
        },
        setToken(token) {
          if (token) {
            this.currentUser.token = token
          } else {
            localStorage.removeItem('currentUser')
            this.$router.push({ name: 'Login' })
          }
        }
      }
    })
  }
}
