/* eslint-disable */

export default {
  methods: {
    /* ok */
    _tryToLogin(data) {
      return this.makeReq({
        method: 'POST',
        url: 'panel/client/login',
        data
      })
    },
    /* ok */
    _readSettings() {
      return this.makeReq({
        method: 'GET',
        url: 'panel/client/settings/read'
      })
    },
    /* ok */
    _paymentMethodList() {
      return this.makeReq({
        method: 'GET',
        url: 'panel/client/pmethod/list'
      })
    },
    /* ok */
    _transactionList(data) {
      return this.makeReq({
        method: 'POST',
        url: 'panel/client/transaction/list',
        data,
        returnAll: true
      })
    },
    _readTransaction(id) {
      return this.makeReq({
        method: 'GET',
        url: `panel/client/transaction/read/${id}`
      })
    },
    _getBalances(query) {
      return this.makeReq({
        method: 'POST',
        url: 'panel/client/balances/list',
        data: query,
        returnAll: true
      })
    },
    _getBalance() {
      return this.makeReq({
        method: 'GET',
        url: 'panel/client/balance/read'
      })
    },
    _updatePassword(data) {
      console.log(data)
      return this.makeReq({
        method: 'POST',
        url: 'panel/client/settings/changepass',
        data,
        returnAll: true
      })
    },
    _getRefundList(query) {
      return this.makeReq({
        method: 'POST',
        url: 'panel/client/refundrequest/list',
        data: query,
        returnAll: true
      })
    },
    _refundPayment(id) {
      return this.makeReq({
        method: 'GET',
        url: `panel/client/transaction/refund/${id}`
      })
    }
  }
}
